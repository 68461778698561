import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import './App.css'
import ContextProvider from './Context/Context'
import Home from './Home'
import SendEmail from './SendEmail'
import SurveyReact from './SurveyReact'
import Surveys from './Surveys'

function App() {
  return (
    <div className="App">
       <Router>
       <ContextProvider>
          <Route exact path='/'>
            <Home /> 
          </Route>
          <Route exact path='/encuesta'>
            <SurveyReact/>
          </Route>
          <Route exact path='/enviar'>
            <SendEmail/>
          </Route>
          <Route exact path='/encuestas'>
            <Surveys/>
          </Route>
        </ContextProvider>
      </Router>
    </div>
  );
}

export default App;
