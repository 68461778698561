import React, { useState, useEffect } from 'react'
import * as Survey from "survey-react"
import emailjs from 'emailjs-com'
import logo from './assets/logo.png'

import "survey-react/survey.css"
import Survey3 from './surveys/survey3.json'

export default function SurveyReact() {
    const [values,setValues] = useState('')

    const survey = new Survey.Model(Survey3)

    const sendData = (a,b) => {
        var answers = ''
        for(var i=1;i<31;i++) {
            answers= answers + 'pregunta'+i+': '+ a['q'+i]+ ' ,'
        }
        
        var templateParams = {
            user_email:'evaluacioncandidatos@ljtalent.com.pe',
            message:'Candidato '+a.name+' ('+a.email+'), obtuvo un puntaje de '+b+'. Sus respuestas son las siguientes: '+answers
        };
        //console.log('answers',a)
        //console.log('correct',b)
        
        emailjs.send('service_zdh7usx', 'template_pwdpkkb', templateParams,'user_V3utzsY0f3UJbjxvoONLR')
        .then(function(response) {
            setValues(response.text)
            //console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
            setValues(error)
            //console.log('FAILED...', error);
        })
    }
    
    useEffect(() => {
        document.title = 'Test #2 - LJ Talent'
    }, [])

    return (
      <div>
          <div className='surveyBG'>
              <img className='menuLogo' src={logo} alt='logo' />
          </div>
          <Survey.Survey
              model={survey}
              onComplete={()=> sendData(survey.data,survey.getCorrectedAnswers())}
          /> 
      </div>
    )
}

