import React, {useContext, useEffect} from 'react'
import MainBar from './common/MainBar'
import { MyContext } from './Context/Context'
import Login from './Login'
import { Link } from 'react-router-dom'

export default function Surveys() {
    const {rootState} = useContext(MyContext)
    const {isAuth} = rootState

    useEffect(() => {
        document.title = 'Intranet - LJ Talent'
    }, [])

    if(isAuth){
        return (
            <div>
                <MainBar/><br/><br/>    
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 text-left'>
                        <span className='generalTitle2'>ENCUESTAS</span>
                            <p className='generalFont1'>A continuación se muestran todas las encuestas disponibles.</p>
                            <button className="btn buttonMenu1">
                                <a className='generalColor' href='https://extranet.ljtalent.com.pe/surveys/test2/' target='blank'>Prueba PMA - FactorR</a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div>
                <Login/>
            </div>
        )
    }
}
