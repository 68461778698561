import React,{useContext,useEffect} from 'react'
import MainBar from './common/MainBar'
import { MyContext } from './Context/Context'
import Login from './Login'

export default function Home() {
    const {rootState} = useContext(MyContext)
    const {isAuth} = rootState

    useEffect(() => {
        document.title = 'Intranet - LJ Talent'
    }, [])

    if(isAuth){
        return (
            <div>
                <MainBar/>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'><br/>
                            <span>Bienvenid@!</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div>
                <Login/>
            </div>
        )
    }
}
