import React, { Component,createContext } from 'react'
import axios from 'axios'
export const MyContext = createContext()

const Axios = axios.create({
    baseURL:'https://intranet.ljtalent.com.pe/php/'
})

export default class ContextProvider extends Component {
    constructor(){
        super()
        this.isLoggedIn()
    }

    state = {
        showLogin: true,
        isAuth: false,
        theUser:null
    }

    logoutUser = () => {
        //localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth:false
        })
    }

    loginUser = async (userIn) => {

        const login = await Axios.post('login.php',{
            user:userIn.user,
            password:userIn.password
        });
        return login.data;
        
    }

    isLoggedIn = async (data) => {
        console.log(data)
            this.setState({
                ...this.state,
                isAuth:true,
                theUser:data
            })        
    }

    render() {
        const contextValue = {
            rootState:this.state,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser,
            isLoggedIn:this.isLoggedIn,
        }
        return (
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}
