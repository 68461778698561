import React, {useState,useContext,useEffect} from 'react'
import emailjs from 'emailjs-com'
import Select from 'react-select'
import MainBar from './common/MainBar'
import { MyContext } from './Context/Context'
import Login from './Login'

//import './ContactUs.css';

export default function SendEmail() {
    const {rootState} = useContext(MyContext)
    const {isAuth} = rootState

    const [text,setText] = useState('')
    const [flag, setFlag] = useState(true)
    const [template, setTemplate] = useState({value:'',label:''})
    const options = [{value:'template_pxrzfwn',label:'Prueba PMA - FactorR'}]

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_zdh7usx', template.value, e.target, 'user_V3utzsY0f3UJbjxvoONLR')
        .then((result) => {
            setText('Email enviado con éxito')
            setFlag(false)
            //console.log(result.text);
        }, (error) => {
            setText(error.text)
            //console.log(error.text)
        });
    }

    const temp = (f,g) =>{
        setTemplate({value:f,label:g})
    }

    useEffect(() => {
        document.title = 'Intranet - LJ Talent'
    }, [])

    if(isAuth){
        return (
            <div>
                <MainBar/><br/><br/>    
                <div className='container'>
                    <div className='row'>
                        {flag &&
                        <div className='col-md-12 text-left'>
                            <span className='generalTitle2'>ENVIO DE CAMPAÑA</span>
                            <p className='generalFont1'>Escoja el tipo de prueba que se va a enviar y luego escriba los datos de la persona que la recibirá.</p>
                            <div className='form-group generalFont1'>
                                <label for="template">TIPO DE PRUEBA</label>
                                
                                <Select
                                    defaultValue={template}
                                    value={template}
                                    options={options}
                                    onChange={(option) => temp(option.value,option.label)}
                                    
                                />
                            </div>
                            <form onSubmit={sendEmail}>
                                <input type="hidden" name="contact_number" />
                                <div className='form-group generalFont1'>
                                    <label>NOMBRE</label>
                                    <input className="form-control" type="text" name="user_name" required />
                                </div>
                                <div className="form-group generalFont1">
                                    <label>EMAIL</label>
                                    <input className="form-control" type="email" name="user_email" required />
                                </div>
                                <button className='btn buttonMenu1' type="submit">
                                    Enviar
                                </button>
                            </form>
                        </div>
                        }
                        {!flag &&
                        <div className='col-md-12'>
                            <span className="generalFont1">{text}</span><br/>
                            <button className='btn buttonMenu1' onClick={() => setFlag(true)}>
                                Enviar otro email
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div>
                <Login/>
            </div>
        )
    }
}