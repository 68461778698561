import React,{useContext} from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.png'
import { MyContext } from '../Context/Context'

export default function MainBar() {
    const {logoutUser} = useContext(MyContext)

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-left'>
                        <img className='menuLogo' src={logo} alt='logo' />
                    </div>
                    <div className='col-md-12 text-left'><br/>
                        <Link to="/" className="btn buttonMenu">
                            <span className='buttonFont'>Inicio</span>
                        </Link>
                        <Link to="/enviar" className="btn buttonMenu">
                            <span className='buttonFont'>Envio Campaña</span>
                        </Link>
                        <Link to="/encuestas" className="btn buttonMenu">
                            <span className='buttonFont'>Encuestas</span>
                        </Link>
                        <button className='btn buttonMenu' onClick={logoutUser}>
                                <span className='buttonFont'>Salir</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
