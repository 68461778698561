import React,{useContext,useState} from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { Col, Container,Row } from 'react-bootstrap'
import logo from './assets/logo.png'
import { MyContext } from './Context/Context'

export default function Login() {
    const {loginUser,isLoggedIn} = useContext(MyContext)

    const initialState = {
        userInfo:{
            user:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }
  
    const [state,setState] = useState(initialState)

    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
            });
            await isLoggedIn(state.userInfo.user)
            console.log('login success')
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            console.log('login error')
        }
        console.log(state.userInfo)
    }

    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    return (
        <div>
            <Modal
            show={true}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Body className='show-grid general'>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className='text-center'>
                                    <img className='mainLogo' src={logo} alt='logo' />
                                </div>
                            </Col>
                            <Col  md={6}>
                                <div className='text-center'>
                                    <span className='generalTitle'>
                                    Intranet
                                    </span>
                                </div>
                                <form  noValidate onSubmit={submitForm}>
                                    <div className="form-group">
                                        <label className='generalFont' for="exampleInputEmail1">USUARIO</label>
                                        <input 
                                        name='user'
                                        required
                                        type="text"
                                        className="form-control generalFont1"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp" 
                                        placeholder="Ingrese su usuario" 
                                        value={state.userInfo.user}
                                        onChange={onChangeValue}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className='generalFont' for="exampleInputPassword1">CONTRASEÑA</label>
                                        <input 
                                        name='password'
                                        required
                                        type="password"
                                        className="form-control generalFont1"
                                        id="exampleInputPassword1"
                                        placeholder="Ingrese su contraseña" 
                                        value={state.userInfo.password}
                                        onChange={onChangeValue}
                                        />
                                    </div>
                                    {errorMsg}
                                    {successMsg}
                                    <Row className='addPadding'>
                                        <Col  md={6}>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label generalFont1" for="exampleCheck1">Recordar</label>
                                        </div>
                                        </Col>
                                    </Row>
                                    <div className='text-center'>
                                        <button type="submit" className="btn buttonForm">
                                            <span className='buttonFont'>Iniciar Sesión</span>
                                        </button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
